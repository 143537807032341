import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { useQuery } from "react-query";
import { handleUseQueryError } from "../../../utils/errors/handleUseQueryError";
import { useCoreSDKContext } from "../useCoreSDKContext";
export const useGetOffers = (props) => {
    const coreSDK = useCoreSDKContext();
    const { address } = bosonHooks.useAccount();
    const options = props?.options
        ? props.options
        : { enabled: !!address && props?.options?.enabled !== false };
    return handleUseQueryError(useQuery(["getOffers", coreSDK.uuid, props?.queryVars], async () => {
        const offers = await coreSDK.getOffers({
            ...props?.queryVars,
            includeFullOffer: (props?.queryVars?.includeFullOffer ?? false),
        });
        return offers;
    }, options));
};
