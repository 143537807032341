import { ConnectWallet, hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { colors, hooks } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { Wallet } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { fermionRoutes } from "router/routeNames";
import { errorButtonTheme, successButtonTheme } from "styles/themes";

const connectedButtonTheme = {
  ...successButtonTheme,
  color: colors.greyDark,
  background: colors.white,
  borderColor: colors.white,
  hover: {
    background: colors.shade,
    color: colors.black,
    borderColor: colors.borderShade,
  },
} satisfies Parameters<typeof ConnectWallet>[0]["connectedButtonTheme"];
export const FermionConnectWallet = ({
  clickedOnWalletOption,
}: {
  clickedOnWalletOption?: boolean;
}) => {
  const navigate = useCustomNavigate();
  const location = useLocation();
  const { showModal } = useModal();

  const { address } = bosonHooks.useAccount();
  const { data, isSuccess } = hooks.useGetFNFTs({
    options: {
      enabled: !!address,
    },
    queryVars: {
      fnftsFilter: {
        or: [
          { owner: address?.toLowerCase() },
          { checkoutRequest_: { owner: address?.toLowerCase() } },
        ],
      },
      fnftsFirst: 1,
      includeFNFTs: false,
      includeFNFTRanges: false,
      includeOfferEntities: false,
      includeSeller: false,
      includeVerifier: false,
      includeFacilitator: false,
      includeCustodian: false,
      includeFNFTVerifier: false,
      includeFNFTCustodian: false,
    },
  });
  const prevAddress = useRef<string | undefined>();
  const [justConnectedWallet, setJustConnectedWallet] = useState(false);
  const [shownCreateProfileModal, setShownCreateProfileModal] = useState(false);

  useEffect(() => {
    const hasJustConnected = !prevAddress.current && !!address;
    setJustConnectedWallet(hasJustConnected);
    prevAddress.current = address;
  }, [address]);

  useEffect(() => {
    if (!address) {
      setShownCreateProfileModal(false);
    }
  }, [address]);

  useEffect(() => {
    if (
      justConnectedWallet &&
      isSuccess &&
      clickedOnWalletOption &&
      !location.pathname.startsWith(fermionRoutes.buyer) &&
      !shownCreateProfileModal
    ) {
      const hasFnfts = !!data.length;
      if (hasFnfts) {
        navigate({ pathname: fermionRoutes.buyer });
      } else {
        showModal({ modalType: "CREATE_PROFILE" });
        setShownCreateProfileModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, clickedOnWalletOption, justConnectedWallet]);
  return (
    <ConnectWallet
      connectWalletButtonTheme={successButtonTheme}
      connectedButtonTheme={connectedButtonTheme}
      connectWalletChild={<>Connect</>}
      rightConnectedChild={<Wallet />}
      rightConnectWalletChild={<Wallet />}
      showStatusIcon={false}
      errorButtonTheme={errorButtonTheme}
    />
  );
};
