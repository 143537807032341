import {
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { XLogo } from "@phosphor-icons/react";
import diamondImage from "assets/diamond.png";
import FooterLogo from "assets/f-logo.svg?react";
import { exploreAssetsLabel } from "components/homepage/ExploreAssetsLink";
import { CustomLink, RouteValue } from "components/navigation/CustomLink";
import { PageContainer } from "components/page/PageContainer";
import { TransparentButton } from "components/table/TableStyles";
import { CONFIG } from "config";
import { HOMEPAGE_CONTAINER_BREAKING_POINT } from "pages/home/const";
import { DiscordLogo, MediumLogo, TelegramLogo } from "phosphor-react";
import React, { RefObject, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { fermionProtocolRoutes, fermionRoutes } from "router/routeNames";
import { styled } from "styled-components";
import { maxWidthStyles } from "styles/layout";
import { useAppContext } from "useAppContext";
const FOOTER_GRID_BREAKPOINT = 600;

const StyledFooter = styled.footer`
  width: 100%;
  margin-top: auto;
  background: ${colors.velvet};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  position: relative;
  > * {
    ${maxWidthStyles};
  }

  svg {
    path:not(:last-child) {
      fill: ${colors.white};
    }
  }
`;

const SocialIcon = styled.a`
  color: ${colors.white};
  border: 1px solid ${colors.velvetBorderDark};
  padding: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &:hover {
    color: ${colors.pink};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.velvetBorderDark};
  margin-top: 1.5625rem;
  margin-bottom: 3.5rem;
`;

const DiamondImage = styled.img`
  position: absolute;
  right: 0;
  top: -6.5625rem;
  transform: rotate(180deg);
  pointer-events: none;
`;

interface FooterGridProps {
  $isMobile: boolean;
}

const FooterGrid = styled(Grid)<FooterGridProps>`
  display: grid;
  grid-template-columns: ${({ $isMobile }) =>
    $isMobile ? "100%" : "3.5fr 6.5fr"};
  margin: 0 auto;
`;

interface MenuItem {
  title: string;
  link?: RouteValue | string;
  ref?: RefObject<HTMLElement>;
  external?: boolean;
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

const socialIcons = [
  { Icon: DiscordLogo, link: "https://discord.gg/fermion" },
  { Icon: TelegramLogo, link: "https://t.me/fermionprotocol" },
  { Icon: XLogo, link: "https://x.com/FermionProtocol" },
  { Icon: MediumLogo, link: "https://medium.com/@FermionProtocol" },
];

const footerLinks = [
  { title: "Privacy Policy", link: fermionRoutes.privacyPolicy },
  { title: "Terms and Conditions", link: fermionRoutes.termsAndConditions },
  // TODO: link fix
  // { title: "Community Guidelines", link: fermionRoutes.home },
];

export const Footer: React.FC = () => {
  const windowBreakpoints = bosonHooks.useBreakpoints();
  const isMobile = windowBreakpoints.isLteS;
  const isGridBreakpoint = useMediaQuery({
    query: `(max-width: ${FOOTER_GRID_BREAKPOINT}px)`,
  });
  const {
    buyWithFermionSectionRef,
    sellWithFermionSectionRef,
    advancedRolesSectionRef,
  } = useAppContext();

  const footerMenuSections: MenuSection[] = useMemo(
    () => [
      {
        title: "FERMION PROTOCOL",
        items: [
          { title: "About Fermion", link: fermionProtocolRoutes.aboutFermion },
          {
            title: "Community",
            link: fermionProtocolRoutes.community,
          },
          { title: "Build", link: fermionProtocolRoutes.build },
          {
            title: "Mission & Vision",
            link: fermionProtocolRoutes.missionAndVision,
          },
        ],
      },
      {
        title: "USE FERMION",
        items: [
          {
            title: exploreAssetsLabel,
            link: fermionProtocolRoutes.exploreAssets,
            external: true,
          },
          { title: "Sell with Fermion", ref: sellWithFermionSectionRef },
          { title: "For Verifiers", ref: advancedRolesSectionRef },
          { title: "For Custodians", ref: advancedRolesSectionRef },
          { title: "For Buyers", ref: buyWithFermionSectionRef },
        ],
      },
      {
        title: "RESOURCES",
        items: [
          { title: "Help", link: `mailto:${CONFIG.infoEmail}`, external: true },
        ],
      },
    ],
    [
      sellWithFermionSectionRef,
      advancedRolesSectionRef,
      buyWithFermionSectionRef,
    ],
  );

  return (
    <StyledFooter>
      {!isMobile && <DiamondImage src={diamondImage} />}
      <PageContainer
        paddingTop="2.5rem"
        maxWidth={`${HOMEPAGE_CONTAINER_BREAKING_POINT}px`}
      >
        <Grid flexDirection="column" alignItems="stretch" width="100%">
          <FooterGrid
            $isMobile={isMobile}
            justifyContent={isMobile ? "center" : "space-between"}
            alignItems={isMobile ? "center" : "flex-start"}
            marginBottom={"2rem"}
          >
            <Grid
              flexDirection="column"
              gap="12.5rem"
              alignItems={isMobile ? "center" : "flex-start"}
              marginBottom={isMobile ? "1.875rem" : 0}
            >
              <CustomLink to={fermionRoutes.home}>
                <FooterLogo />
              </CustomLink>
            </Grid>
            <Grid
              justifyContent="flex-start"
              alignItems="flex-start"
              flexDirection={isGridBreakpoint ? "column" : "row"}
              gap={isGridBreakpoint ? "4rem" : 0}
            >
              {footerMenuSections.map((section, index) => (
                <Grid
                  key={index}
                  flexDirection="column"
                  gap="1.25rem"
                  justifyContent="flex-start"
                  alignItems={isMobile ? "center" : "flex-start"}
                >
                  <Typography
                    color={colors.champagneFooter}
                    fontSize={"0.75rem"}
                    letterSpacing={"0.125rem"}
                    marginBottom={isGridBreakpoint ? "0" : "1rem"}
                    tag="p"
                    marginTop={0}
                  >
                    {section.title}
                  </Typography>
                  {section.items.map((item, itemIndex) => {
                    const ItemTitle = (
                      <Typography
                        fontSize={"1rem"}
                        color={colors.champagne}
                        tag="p"
                        marginTop={0}
                        marginBottom={0}
                      >
                        {item.title}
                      </Typography>
                    );
                    if (item.link) {
                      return (
                        <CustomLink
                          key={itemIndex}
                          to={item.link as RouteValue}
                          target={item.external ? "_blank" : undefined}
                          rel={
                            item.external ? "noopener noreferrer" : undefined
                          }
                        >
                          {ItemTitle}
                        </CustomLink>
                      );
                    }
                    if (item.ref) {
                      return (
                        <TransparentButton
                          key={itemIndex}
                          onClick={() => {
                            item?.ref?.current?.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          {ItemTitle}
                        </TransparentButton>
                      );
                    }
                    return ItemTitle;
                  })}
                </Grid>
              ))}
            </Grid>
          </FooterGrid>
          <Grid
            marginTop={isGridBreakpoint ? "2rem" : "1.5625rem"}
            justifyContent={isGridBreakpoint ? "center" : "flex-start"}
          >
            <Grid
              gap="1rem"
              width={"auto"}
              marginLeft={isGridBreakpoint ? "0" : "1.125rem"}
            >
              {socialIcons.map(({ Icon, link }, index) => (
                <SocialIcon
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon size={24} />
                </SocialIcon>
              ))}
            </Grid>
          </Grid>
          <Separator />
          <Grid
            justifyContent="space-between"
            alignItems="center"
            flexDirection={isGridBreakpoint ? "column" : "row"}
          >
            <Grid
              alignItems="center"
              justifyContent={isGridBreakpoint ? "center" : "flex-start"}
              flexDirection={isGridBreakpoint ? "column" : "row"}
              gap={isGridBreakpoint ? "1rem" : 0}
            >
              {footerLinks.map((link, index) => (
                <CustomLink key={index} to={link.link}>
                  <Typography
                    fontSize={"0.875rem"}
                    color={colors.champagneFooter}
                    tag="p"
                    marginRight={
                      isGridBreakpoint
                        ? 0
                        : index < footerLinks.length - 1
                          ? "2rem"
                          : 0
                    }
                  >
                    {link.title}
                  </Typography>
                </CustomLink>
              ))}
            </Grid>
            <Grid
              alignItems="center"
              justifyContent={isGridBreakpoint ? "center" : "flex-end"}
              marginTop={isGridBreakpoint ? "1rem" : "0"}
            >
              <Typography
                tag="p"
                color={colors.champagneFooter}
                fontSize={"0.875rem"}
              >
                Copyright © 2024 Fermion. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </StyledFooter>
  );
};
